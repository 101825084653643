import $ from 'jquery'
$(window).on('load',function () {
  $('.loader').fadeOut(300,function () {
    $('.full-height').addClass('active')
    // $('.logo').addClass('animated zoomInDown')
    // $('.title').addClass('animated zoomInUp')
    // $('.des').addClass('animated zoomInDown')
    // $('.button').addClass('animated zoomInUp slow')

    // $('.logo').addClass('animated fadeIn slow')
    // $('.title').addClass('animated fadeIn slow')
    // $('.des').addClass('animated fadeIn slow')
    // $('.button').addClass('animated fadeIn slower')
  })
})
